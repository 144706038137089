import fastdom from "fastdom";
import fastdomPromised from "fastdom/extensions/fastdom-promised";
import { promiseDefer } from "./constants";
import visibilityjs from "visibilityjs/lib/visibility.core";

const fastdomMb = fastdom.extend({
  measure(fn, ctx) {
    return visibilityjs.hidden() ? (ctx ? fn.bind(ctx) : fn()) : this.fastdom.measure(fn, ctx);
  },
  mutate(fn, ctx) {
    return visibilityjs.hidden() ? (ctx ? promiseDefer(fn.bind(ctx)) : promiseDefer(fn)) : this.fastdom.mutate(fn, ctx);
  },
  clear(task) {
    this.fastdom.clear(task);
  },
  catch(theError) {
    console.trace("fastdom.catch", theError);
  },
});

Object.assign(window, {
  visibilityjs,
  fastdomMb,
  fastdomPromised: fastdom.extend(fastdomPromised),
});

export default fastdomMb;
