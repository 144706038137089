import "core-js/stable";
import "regenerator-runtime/runtime";
import "mdn-polyfills/Element.prototype.closest";
import "mdn-polyfills/Element.prototype.matches";
import "mdn-polyfills/Element.prototype.toggleAttribute";
import "mdn-polyfills/Element.prototype.getAttributeNames";
import "mdn-polyfills/HTMLCanvasElement.prototype.toBlob";
import "mdn-polyfills/Node.prototype.after";
import "mdn-polyfills/Node.prototype.append";
import "mdn-polyfills/Node.prototype.before";
import "mdn-polyfills/Node.prototype.children";
import "mdn-polyfills/Node.prototype.firstElementChild";
import "mdn-polyfills/Node.prototype.prepend";
import "mdn-polyfills/Node.prototype.remove";
import "mdn-polyfills/Node.prototype.replaceWith";
import "eligrey-classlist-js-polyfill";
import "events-polyfill/src";
import "is-connected-node/implement";
import "document.contains/auto";
import "scrolling-element";
import "shim-keyboard-event-key";
import "./fastdomMb";
import "mutation-observer-inner-html-shim";
import { polyfill } from "smoothscroll-polyfill";
import "intersection-observer";
polyfill();
