import _ from "lodash-es";
import addEventListener from "add-dom-event-listener";

export const Events = {
  kOptimizedResize: "optimizedresize",
  kOptimizedScroll: "optimizedscroll",
  kDarkModeOn: "darkModeOn",
  kResize: "resize",
  kScroll: "scroll",
  DOMContentLoaded: "DOMContentLoaded",
  kDOMContentLoaded: "kDOMContentLoaded",
};

export const isEmpty = (val) => val == null || !(Object.keys(val) || val).length;
export const isBrowser =
  typeof window !== "undefined" && typeof document !== "undefined" && typeof navigator !== "undefined";
export const newImage = new Image();
export const testImg = _.isUndefined(Image) ? { style: { kObjectPosition: 1 } } : newImage;
export const kWin = isBrowser ? window : {};
export const kDoc = window.document;
export const supportsImageDecode = _.memoize(() => "decode" in newImage);
export const supportsObjectFit = _.memoize(() => "object-fit" in testImg.style);
export const supportsObjectPosition = _.memoize(() => "object-position" in testImg.style);
export const supportsTouch = _.memoize(() => "ontouchstart" in kWin);
export const getUserAgent = _.memoize(() => navigator.userAgent.toLowerCase());
export const supportsPointerEvent = _.memoize(() => "PointerEvent" in kWin || kWin.PointerEvent);
export const isBrowserSupported = _.memoize(() => !!window.MutationObserver || !!window.WebKitMutationObserver);
export const isIE11 = _.memoize(() => isBrowser && !!(window.MSInputMethodContext && document.documentMode));
export const isEdge = _.memoize(() => getUserAgent().includes("edge/"));
export const isAndroid = _.memoize(() => getUserAgent().includes("android"));
export const promiseDefer = Promise.resolve().then.bind(Promise.resolve());
export const isDarkModeSupported = () => kWin.matchMedia && kWin.matchMedia("(prefers-color-scheme: dark)");
export const isOSinDarkMode = () => isDarkModeSupported() && isDarkModeSupported().matches;

export const MBUI = {
  events: {},
  util: {
    //onConnected,
    addEventListener,
    isAndroid,
    isEdge,
    isIE11,
    supportsImageDecode,
    isBrowserSupported,
    supportsObjectFit,
    supportsObjectPosition,
    getUserAgent,
    isDarkModeSupported,
    isOSinDarkMode,
  },
};

Object.assign(window, {
  MBUI,
});
